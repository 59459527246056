import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Fade,
  TextField,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import useData from '../hooks/useData';
import useLogin from '../hooks/useLogin';
import useViewer, { Query } from '../hooks/useViewer';
import ServerHelper, { ServerURL } from './ServerHelper';
import { UserContext } from './UserProvider';
import Autocomplete from '@material-ui/lab/Autocomplete';

const HomePage = () => {
  const { user } = useContext(UserContext);
  const { viewer, isLoggedIn } = useViewer();
  const login = useLogin();
  const [filepath, setFilepath] = useState('/');
  const [results, setResults] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fileFile = async () => {
    setLoading(true);
    const json = await ServerHelper.post(ServerURL.FIND_FILE, { filepath });
    if (!json.success) {
      setLoading(false);
      return;
    }
    console.log(json);
    const results = json.files as string[];
    setResults(results.map((r) => filepath + r));
    setLoading(false);
  };

  useEffect(() => {
    if (filepath.endsWith('/') && !loading) {
      fileFile();
    }
  }, [filepath]);

  if (!isLoggedIn) {
    login.redirectToDopeAuth();
    return null;
  }
  if (!user) {
    return null;
  }
  return (
    <div className="one-page">
      <Container className="py-5 full centered">
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
          className="full"
        >
          <Card className="p-5 main-card">
            <>
              <h1 className="main-font centered">File Serve:</h1>
              <p>User: {user.email}</p>
              <Autocomplete
                fullWidth
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={filepath}
                onChange={(_, val) => setFilepath(val || '')}
                getOptionSelected={(option, value) => option === value}
                getOptionLabel={(option) => option}
                options={
                  results.some((r) => r.includes(filepath))
                    ? results.filter((r) => r.includes(filepath))
                    : [filepath].concat(results)
                }
                loading={open && loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filepath"
                    variant="outlined"
                    onChange={(e) => setFilepath(e.currentTarget.value)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {open && loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
              <Button
                onClick={() => {
                  window.location.href =
                    '/file?f=' + encodeURIComponent(filepath);
                }}
                size="large"
                variant="contained"
                color="primary"
                className="my-2"
                fullWidth
              >
                Go
              </Button>
            </>
          </Card>
        </Box>
      </Container>
    </div>
  );
};

export default HomePage;
