import React, { Fragment, useEffect } from 'react';
import './App.css';
import { CookiesProvider } from 'react-cookie';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import { SnackbarProvider } from 'notistack';
import { UserProvider } from './components/UserProvider';
import HomePage from './components/HomePage';
import ReactGA from 'react-ga';
import FilePage from './components/FilePage';

// Initialize google analytics page view tracking
export const logPage = (): void => {
  ReactGA.set({ page: window.location.pathname }); // Update the user's current page
  ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
};

const ClosePage = () => {
  return <Redirect to="/" />;
};

const App: React.FC = () => {
  document.title = process.env.REACT_APP_NAME || '';

  useEffect(() => {
    console.log('v' + (process.env.REACT_APP_VERSION || ''));
    logPage();
  }, []);

  return (
    <Router>
      <SnackbarProvider>
        <CookiesProvider>
          <UserProvider>
            <div className="App">
              <Switch>
                <Route path="/success" component={ClosePage} />
                <Route path="/file" component={FilePage} />
                <Route path="/" component={HomePage} />
              </Switch>
            </div>
          </UserProvider>
        </CookiesProvider>
      </SnackbarProvider>
    </Router>
  );
};

export default App;
