import CryptoJS from 'crypto-js';

export enum LSKey {
  ORG_DATA = 'org_data',
  TUTORIAL = 'tutorial',
  META = 'meta',
  VERSION = 'version',
}

const useData = () => {
  const getLocalStorageJSON = (key: LSKey) => {
    return JSON.parse(localStorage.getItem(key) || '{}');
  };
  const getLocalStorageJSONOrNULL = (key: LSKey) => {
    if (localStorage.getItem(key) == null) {
      return null;
    }
    return getLocalStorageJSON(key);
  };
  const getLocalStorageOrNULL = (key: LSKey) => {
    return localStorage.getItem(key);
  };
  const setLocalStorageOrRemoveJSON = (key: LSKey, value?: object | null) => {
    if (value == null) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }
  };
  const setLocalStorageOrRemove = (key: LSKey, value?: string | null) => {
    if (value == null) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, value);
    }
  };

  const seenTutorial = (force?: boolean) => {
    if (force != undefined) {
      setLocalStorageOrRemove(LSKey.TUTORIAL, force ? '1' : null);
    }
    return getLocalStorageOrNULL(LSKey.TUTORIAL) === '1';
  };

  const clearAllData = () => {
    setLocalStorageOrRemoveJSON(LSKey.META);
  };

  const generateRandomPassword = () => {
    return CryptoJS.lib.WordArray.random(32).toString(CryptoJS.enc.Base64);
  };

  const hash = (message: string) => {
    return CryptoJS.MD5(message).toString(CryptoJS.enc.Base64);
  };

  const symmetric_encryption = (message: string, key: string) => {
    const iv = CryptoJS.lib.WordArray.random(16);
    const master_key = CryptoJS.enc.Base64.parse(key);
    const cipher = CryptoJS.AES.encrypt(message, master_key, {
      mode: CryptoJS.mode.CFB,
      padding: CryptoJS.pad.Pkcs7,
      iv: iv,
    });
    return CryptoJS.enc.Base64.stringify(cipher.iv) + ':' + cipher.toString();
  };
  const symmetric_decryption = (message: string, key: string) => {
    const master_key = CryptoJS.enc.Base64.parse(key);
    const encrypted = message.split(':');
    const iv = CryptoJS.enc.Base64.parse(encrypted[0]);
    // @ts-ignore
    const cipher = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(encrypted[1]),
    });
    return CryptoJS.AES.decrypt(cipher, master_key, {
      iv: iv,
      mode: CryptoJS.mode.CFB,
    }).toString(CryptoJS.enc.Utf8);
  };

  return {
    getLocalStorageJSON,
    getLocalStorageJSONOrNULL,
    getLocalStorageOrNULL,
    setLocalStorageOrRemove,
    setLocalStorageOrRemoveJSON,
    clearAllData,
    seenTutorial,
    generateRandomPassword,
    symmetric_encryption,
    symmetric_decryption,
    hash,
  };
};

export default useData;
