import React, { useEffect, useState } from 'react';
import { render } from 'react-dom';
import AceEditor from 'react-ace';
import modelist from 'ace-builds/src-noconflict/ext-modelist';

import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-typescript';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/ext-language_tools';
import { Button, Container, Grid } from '@material-ui/core';
import ServerHelper, { ServerURL } from './ServerHelper';
import { useSnackbar } from 'notistack';

const FilePage = () => {
  const search = new URLSearchParams(window.location.search);
  const filepath = decodeURIComponent(search.get('f') || '');
  const [content, setContent] = useState('');
  const [helpContent, setHelpContent] = useState('');
  const [writable, setWritable] = useState(false);
  const snac = useSnackbar();

  const loadFile = async () => {
    const json = await ServerHelper.post(ServerURL.GET_FILE, { filepath });
    if (!json.success) {
      snac.enqueueSnackbar('Could not load file', { variant: 'error' });
      return;
    }
    console.log(json);
    const fileContents = json.file as string;
    setContent(fileContents);
    setHelpContent(json.helperFile);
    setWritable(json.writable);
    snac.enqueueSnackbar('Loaded file', { variant: 'success' });
  };

  const saveFile = async () => {
    const json = await ServerHelper.post(ServerURL.UPLOAD_FILE, {
      filepath,
      content,
    });
    if (!json.success) {
      snac.enqueueSnackbar('Could not save file');
      return;
    }

    snac.enqueueSnackbar('Saved filed', { variant: 'success' });
  };

  useEffect(() => {
    console.log(filepath);
    loadFile();
  }, []);
  
  return (
    <Container className="mt-5">
      {writable ? (
        <Button onClick={saveFile} variant="contained" color="primary">
          Save
        </Button>
      ) : null}
      <Grid container>
        <Grid item xs={helpContent.length > 0 ? 6 : 12}>
          <p>File: {filepath}</p>
          <AceEditor
            mode={modelist.getModeForPath(filepath).mode}
            theme="github"
            value={content}
            onChange={setContent}
            name="ace-editor-one"
            editorProps={{ $blockScrolling: true }}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
            }}
          />
        </Grid>
        {helpContent.length > 0 ? (
          <Grid item xs={6}>
            <p>Helper file:</p>
            <AceEditor
              mode={modelist.getModeForPath(filepath).mode}
              theme="github"
              value={helpContent}
              readOnly={true}
              name="ace-editor-two"
              editorProps={{ $blockScrolling: true }}
            />
          </Grid>
        ) : null}
      </Grid>
    </Container>
  );
};

export default FilePage;
