import React, { useEffect, useState } from 'react';
import { UserMessage } from '../shared/types';
import ServerHelper, { ServerURL } from './ServerHelper';

interface IUserContext {
  user: UserMessage | null;
  updateUser: () => Promise<UserMessage | null>;
}
export const UserContext = React.createContext<IUserContext>(null!);

type UserProviderProps = {
  children: React.ReactNode;
};
export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<UserMessage | null>(null);

  const updateUser = React.useCallback(async () => {
    const json = await ServerHelper.post(ServerURL.GET_SESSION, {});
    if (!json.success) {
      return null;
    }
    const user: UserMessage = json.user;
    setUser(user);
    return user;
  }, [setUser]);

  useEffect(() => {
    updateUser();
  }, [updateUser]);

  return (
    <UserContext.Provider
      value={{
        user,
        updateUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
